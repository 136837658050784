/* Styles */
import {
  FooterText,
  FooterTextTitle,
  ContentWrapper,
} from "@styles/mobile/HomePage";

import { LogoImg } from "@styles/desktop/Navbar";

const Footer = () => {
  return (
    <ContentWrapper>
      <div>
        <LogoImg style={{ width: "80px", marginTop: "80px" }} />
      </div>
      <FooterTextTitle>MEDNERVA - Away From Bias</FooterTextTitle>
      <FooterText>
        University Avenue and, Oxford St, Berkeley, CA 94720
      </FooterText>
      <FooterText>About</FooterText>
      <FooterText>Technology</FooterText>
      <FooterText>Contact us</FooterText>
    </ContentWrapper>
  );
};

export default Footer;
