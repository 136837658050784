import {
  LongContentCardWrapper,
  LongContentCardHeading,
  LongContentCardBody,
} from "@styles/desktop/Card";

import ArrowButtonText from "./ArrowTextButton";

type PropsType = {
  title: string;
  text: string;
  boldText: string;
  footer: string;
  isMobile?: boolean;
};

const LongContentCard = ({
  title,
  text,
  boldText,
  footer,
  isMobile = false,
}: PropsType) => {
  return (
    <LongContentCardWrapper isMobile={isMobile}>
      <div style={{ padding: "20px" }}>
        <LongContentCardHeading isMobile={isMobile}>
          {title}
        </LongContentCardHeading>
        <LongContentCardBody isMobile={isMobile}>
          {text}
          <br />
          <br /> <strong>{boldText}</strong>
        </LongContentCardBody>
        <ArrowButtonText
          text={footer}
          overrideTextStyle={{ fontSize: "14px" }}
        />
      </div>
    </LongContentCardWrapper>
  );
};

export default LongContentCard;
