import styled from "styled-components";

export const AboutHeaderSection = styled.section`
  border-radius: 0px 0px 24px 24px;
  background: linear-gradient(
    95deg,
    rgba(1, 188, 200, 0.5) 0%,
    rgba(144, 255, 228, 0.5) 100%
  );
  justify-content: flex-start;
  align-items: flex-end;
  min-height: 250px;
  display: flex;
`;

export const AboutHeaderBreadcrumb = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  color: white;
  letter-spacing: 1.2px;
`;

export const BreadcrumbLine = styled.div`
  background-color: white;
  width: 25px;
  height: 1px;
`;

export const BreadcrumbText = styled.div`
  margin-left: 10px;
  font-size: 18px;
  line-height: 1.6em;
`;

export const PersonCardTitle = styled.div`
  font-size: 32px;
  line-height: 1.4em;
  color: white;
`;
