type PropsType = {
  overrideStyle?: any;
};

const ArrowCircle = ({ overrideStyle }: PropsType) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      style={{ marginRight: "10px" }}
    >
      <circle cx="20" cy="20" r="20" fill="white" />
      <path d="M18 15L23 20L18 25" stroke="#111224" />
    </svg>
  );
};
export default ArrowCircle;
