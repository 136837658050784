import {
  Route,
  Routes,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";

import HomePageSwitch from "@pageSwitch/HomePageSwitch";
import AboutPageSwitch from "@pageSwitch/AboutPageSwitch";
import DashboardPage from "@desktop/DashboardPage";
import {
  HOME_PAGE,
  DASHBOARD_PAGE,
  ABOUT_PAGE,
  TECH_PAGE,
  CONTACT_PAGE,
} from "./Routes";
import DemoPage from "@desktop/AboutPage";
import TechnologyPage from "@desktop/TechPage";
import ContactPage from "@desktop/ContactPage";

const ScrollToTop = (): any => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path={HOME_PAGE} element={<HomePageSwitch />} />
        <Route path={DASHBOARD_PAGE} element={<DashboardPage />} />
        <Route path={ABOUT_PAGE} element={<AboutPageSwitch />} />
        <Route path={TECH_PAGE} element={<TechnologyPage />} />
        <Route path={CONTACT_PAGE} element={<ContactPage />} />
      </Routes>
    </Router>
  );
};

export default App;
