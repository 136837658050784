import Navbar from "@desktop/Navbar";

/* Styles */
import {
  Wrapper,
  ContentWrapper,
  CardContainer,
} from "@styles/desktop/HomePage";
import {
  AboutHeaderSection,
  AboutHeaderBreadcrumb,
  BreadcrumbLine,
  BreadcrumbText,
  PersonCardTitle,
} from "@styles/desktop/ContactPage";

/* Components */
import BottomContent from "@desktop/BottomSection";
import PersonCard from "@common/PersonCard";
import ContactSection from "@common/ContactSection";

const ContactPage = () => {
  return (
    <>
      <Navbar />
      <Wrapper>
        <AboutHeaderSection
          style={{
            background:
              "background: linear-gradient(95deg, rgba(131, 232, 255, 0.50) 0%, rgba(180, 255, 144, 0.50) 100%);",
          }}
        >
          <ContentWrapper>
            <AboutHeaderBreadcrumb>
              <BreadcrumbLine />
              <BreadcrumbText>Contact</BreadcrumbText>
            </AboutHeaderBreadcrumb>
          </ContentWrapper>
        </AboutHeaderSection>
        <ContentWrapper>
          <ContactSection />
        </ContentWrapper>
        <ContentWrapper>
          <PersonCardTitle>Contact the leadership team</PersonCardTitle>
        </ContentWrapper>
        <CardContainer>
          <PersonCard
            name="David Whitney"
            title="Director of Vision Science UC Berkeley"
            email="david@mednerva.ai"
            phoneNumber="+12 3456 8799"
          />
          <PersonCard
            name="David Whitney"
            title="Director of Vision Science UC Berkeley"
            email="david@mednerva.ai"
            phoneNumber="+12 3456 8799"
          />
          <PersonCard
            name="David Whitney"
            title="Director of Vision Science UC Berkeley"
            email="david@mednerva.ai"
            phoneNumber="+12 3456 8799"
          />
        </CardContainer>
        <div style={{ height: "100px" }} />
        <BottomContent />
      </Wrapper>
    </>
  );
};

export default ContactPage;
