import styled, { keyframes, css } from "styled-components";
import doctor from "@assets/images/doctor.png";
import DavidImg from "@assets/images/david.png";

// Keyframes for the animation
const scrollUpAnimation = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Wrapper = styled.div<{ isVisible: boolean }>`
  max-width: 450px;
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(
    56deg,
    rgba(1, 188, 200, 0.05) 0%,
    rgba(1, 188, 200, 0.25) 100%
  );
  transition: opacity 1s ease-out, transform 1s ease-out;

  ${({ isVisible }) => (isVisible ? "opacity : 1;" : "opacity:0;")}


}
`;

export const Header = styled.div<{ isMobile?: boolean }>`
  color: #ffe5cd;
  font-size: ${(props) => (props.isMobile ? "50px" : "70px")};
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
`;

export const HeaderSuffix = styled.div<{ isMobile?: boolean }>`
  color: #ffe5cd;
  font-size: ${(props) => (props.isMobile ? "16px" : "32px")};
  font-style: normal;
  font-weight: 400;
  margin-top: 32px;
`;

export const BodyItemWrapper = styled.div`
  display: flex;
  margin: 10px 0;
`;

export const BodyItemText = styled.div<{ isMobile?: boolean }>`
  color: #fff;
  font-family: "DM Sans", sans-serif;
  font-size: ${(props) => (props.isMobile ? "14px" : "16px")};
  font-style: normal;
  font-weight: 400;
  line-height: 25.5px; /* 150% */
  margin-top: 20px;
  text-align: left;
`;

export const BodyFooterText = styled.div<{ isMobile: boolean }>`
  font-family: "DM Sans", sans-serif;
  color: rgba(255, 255, 255, 0.5);
  font-size: ${(props) => (props.isMobile ? "10px" : "12x")};
  font-style: normal;
  font-weight: 400;
  line-height: 19.2px; /* 160% */
  margin-top: 32px;
  text-align: left;
`;

export const ImageCardWrapper = styled.div<{
  isMobile?: boolean;
  isVisible?: boolean;
}>`
  background-color: #fff;
  border-radius: 16px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${({ isMobile }) => (isMobile ? "18px" : "25px")};
  display: flex;
  box-shadow: 0 10px 40px #11122426;
  max-width: 450px;
  ${({ isMobile }) => isMobile && "margin-top:20px"};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) =>
    isVisible ? "translateY(0)" : "translateY(100px)"};
  ${({ isVisible }) =>
    isVisible &&
    css`
      animation: ${scrollUpAnimation} 1s ease-out;
    `}
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
`;

export const ImageCardPrefix = styled.div<{ isMobile?: boolean }>`
  color: #111224;
  font-size: ${({ isMobile }) => (isMobile ? "14px" : "15px")};
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 160% */
`;

export const ImageCardTitle = styled.div<{ isMobile?: boolean }>`
  color: #111224;
  font-size: ${({ isMobile }) => (isMobile ? "18px" : "24px")};
  font-style: normal;
  font-weight: 400;
  margin-top: ${({ isMobile }) => (isMobile ? "1px" : "10px")};
`;

export const ImageCardBody = styled.div`
  color: black;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 160% */
  margin: 10px 0;
`;

export const ImageWrapper = styled.div`
  margin: 10px auto;
  border-radius: 16px;
`;

export const ImageCardBodyList = styled.ul`
  margin-top: 5px;
  margin-bottom: auto;
  padding-left: 0;
`;

export const ImageCardContentWrapper = styled.div`
  border-radius: 12px;
  background: rgba(123, 104, 255, 0.15);
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  display: flex;
  box-shadow: 0 10px 40px #11122426;
`;

export const ImageCardTextWrapper = styled.div`
  flex-flow: column;
  justify-content: space-between;
  margin-bottom: auto;
`;

export const ImageWrapperSmall = styled.div`
  opacity: 1;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  background-image: linear-gradient(45deg, #7b68ff0d, #7b68ff40);
  border: 1px solid #ffffff1a;
  border-radius: 16px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 25px;
  display: flex;
  height: 352px;
  width:331px:
`;

export const ImageCardTextTitleWrapper = styled.h3`
  margin-top: 10px;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.4em;
`;

export const ImageCardTextBodyWrapper = styled.h3`
  opacity: 0.8;
  margin-top: 10px;
  font-family: DM Sans, sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.6em;
`;

export const ImageCardBodyListItem = styled.li<{ isMobile?: boolean }>`
  justify-content: flex-start;
  align-items: flex-start;
  list-style-type: none;
  display: flex;
  ${({ isMobile }) =>
    isMobile && "color:black; text-align:left; font-size:13px;"}
`;

export const DoctorImg = styled.img.attrs({ src: doctor })`
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
  border-radius: 16px;
`;

export const LongContentCardWrapper = styled.div<{ isMobile: boolean }>`
  background-image: linear-gradient(
    66deg,
    rgba(1, 188, 200, 0.15) 0%,
    rgba(1, 188, 200, 0.5) 100%
  );
  border: 1px solid #ffffff1a;
  border-radius: 16px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${({ isMobile }) => (isMobile ? "4px" : "30px")};
  display: flex;
  font-weight: 300;
  text-align: left;
  ${({ isMobile }) => isMobile && "margin-top:20px"}
`;

export const LongContentCardHeading = styled.div<{ isMobile: boolean }>`
  color: #fff;
  font-size: ${({ isMobile }) => (isMobile ? "18px" : "24px")};
  font-style: normal;
  font-weight: 400;
  line-height: 1.4em;
`;

export const LongContentCardBody = styled.p<{ isMobile: boolean }>`
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: ${({ isMobile }) => (isMobile ? "15px" : "17px")};
  font-style: normal;
  font-weight: 300;
  line-height: 25.5px; /* 150% */
`;

export const LongContentCardFooterWrapper = styled.a`
  color: white;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  margin-top: auto;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  display: flex;
`;

export const PersonPhotoWrapper = styled.div`
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  display: flex;
`;

export const PersonCardWrapper = styled.div`
  max-width: 450px;
  position: relative;
  color: white;
  font-weight: 400;
`;

export const PersonImage = styled.img.attrs({ src: DavidImg })`
  border-radius: 16px;
  width: 100%;
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
`;

export const PersonCardDetail = styled.div`
  z-index: 10;
  margin-right: 50px;
  position: absolute;
  inset: auto auto 25px 25px;
  opacity: 0.9;
  font-weight: 300;
  text-align: left;
`;

export const PersonCardTitle = styled.h4<{ isMobile?: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? "16px" : "20px")};
  line-height: 1.4em;
  margin-bottom: 0;
  font-weight: 400;
  letter-spacing: 1px;
  ${({ isMobile }) => isMobile && "margin-bottom:6px"}
`;

export const PersonCardLinkedInWrapper = styled.div<{ isMobile?: boolean }>`
  z-index: 10;
  background-color: #fff0;
  border: 1px solid #ffffff80;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  ${({ isMobile }) =>
    isMobile
      ? "width:20px; height:20px; padding:8px;"
      : "width:40px; height:40px; padding:12px;"}
  transition: all 0.15s;
  display: flex;
  position: absolute;
  inset: auto 25px 25px auto;
`;
