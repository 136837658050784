import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Linkedin from "@assets/icons/LinkedIn";
import {
  PersonCardWrapper,
  PersonImage,
  PersonCardDetail,
  PersonCardLinkedInWrapper,
  PersonCardTitle,
} from "@styles/desktop/Card";

type PropsType = {
  name: string;
  title: string;
  email: string;
  phoneNumber: string;
  profileUrl?: string;
  isMobile?: boolean;
};

const PersonCard = ({
  name,
  title,
  email,
  phoneNumber,
  profileUrl,
  isMobile = false,
}: PropsType) => {
  const subTitleFontSize = isMobile ? "14px" : "18px";
  return (
    <PersonCardWrapper>
      <PersonImage />
      <PersonCardDetail>
        <PersonCardTitle isMobile={isMobile}>{name}</PersonCardTitle>
        <div style={{ fontSize: subTitleFontSize }}>{title}</div>
        {!isMobile && (
          <>
            <div style={{ margin: "20px 0 10px" }}>{email}</div>
            <div>{phoneNumber}</div>
          </>
        )}
      </PersonCardDetail>
      <PersonCardLinkedInWrapper isMobile={isMobile}>
        <Linkedin />
      </PersonCardLinkedInWrapper>
    </PersonCardWrapper>
  );
};

export default PersonCard;
