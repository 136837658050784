import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ABOUT_PAGE } from "../../Routes";
import Dot from "@assets/icons/Dot";
import {
  ImageCardWrapper,
  ImageCardPrefix,
  ImageCardTitle,
  ImageCardContentWrapper,
  ImageCardBody,
  ImageWrapper,
  ImageWrapperSmall,
  ImageCardTextWrapper,
  ImageCardTextTitleWrapper,
  ImageCardTextBodyWrapper,
} from "@styles/desktop/Card";

import SquareImage from "@assets/icons/SquareImage";

type PropsType = {
  prefix?: string;
  title: string;
  body: string;
  image?: React.ReactNode;
};

const IconCard = ({ prefix, title, body, image }: PropsType) => {
  return prefix ? (
    <ImageCardWrapper style={{}}>
      <ImageWrapper>
        <SquareImage />
      </ImageWrapper>
      <ImageCardContentWrapper>
        <ImageCardPrefix>{prefix}</ImageCardPrefix>
        <ImageCardTitle>{title}</ImageCardTitle>
        <ImageCardBody>{body}</ImageCardBody>
      </ImageCardContentWrapper>
    </ImageCardWrapper>
  ) : (
    <ImageWrapperSmall>
      <SquareImage overrideStyle={{ width: "114px", height: "80px" }} />
      <ImageCardTextWrapper>
        <ImageCardTextTitleWrapper>{title}</ImageCardTextTitleWrapper>
        <ImageCardTextBodyWrapper>{body}</ImageCardTextBodyWrapper>
      </ImageCardTextWrapper>
    </ImageWrapperSmall>
  );
};

export default IconCard;
