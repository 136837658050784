import { Collapse } from "antd";
import { ABOUT_PAGE } from "../../Routes";

const { Panel } = Collapse;

import Button, { BUTTON_TYPE } from "@common/Button";

import {
  Wrapper,
  Text,
  TextContainer,
  DetailText,
} from "@styles/mobile/DropdownPanel";
import { useNavigate } from "react-router-dom";

type PropsType = { setShouldChangeHeight: (bol: boolean) => void };

const DropdownPanel = ({ setShouldChangeHeight }: PropsType) => {
  const navigate = useNavigate();
  // const onChange = (key: string | string[]) => {
  //   console.log(key);
  //   key.length > 0 ? setShouldChangeHeight(true) : setShouldChangeHeight(false);
  // };
  return (
    <Wrapper>
      <TextContainer>
        <Collapse
          ghost
          bordered={false}
          style={{ background: "transparent", color: "white" }}
        >
          <Panel
            header={
              <Text
                onClick={() => {
                  navigate("/");
                }}
              >
                Home
              </Text>
            }
            key="1"
            collapsible="disabled"
          />
          <Panel
            header={
              <Text
                onClick={() => {
                  navigate(ABOUT_PAGE);
                }}
              >
                About
              </Text>
            }
            key="2"
            collapsible="disabled"
          />
          <Panel
            header={<Text>Technology</Text>}
            key="3"
            collapsible="disabled"
          />
          <Panel
            key="3"
            collapsible="disabled"
            header={
              <Button
                variant={BUTTON_TYPE.GHOST}
                overrideStyle={{
                  color: "white",
                  borderRadius: "60px",
                  border: "1px solid rgba(1, 188, 200, 0.50",
                  padding: "8px 16px",
                  marginTop: "4px",
                  fontWeight: 300,
                  fontSize: "14px",
                  margin: "auto",
                  width: "30%",
                }}
                onClick={() => {}}
              >
                Test our tech
              </Button>
            }
          ></Panel>
        </Collapse>
      </TextContainer>
    </Wrapper>
  );
};

export default DropdownPanel;
