import styled from "styled-components";
import Logo from "@assets/images/logo.png";

export const Wrapper = styled.div<{
  showDropdown?: boolean;
  initialHeight: number;
}>`
  z-index: 2000;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  margin-top: ${(props) => (props.showDropdown ? "0px" : "20px")};
  background: ${(props) => props.showDropdown && "#111224"};
`;

export const MainContent = styled.div`
  background-color: #040716cc;
  border-radius: 100vw;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px 8px 15px;
  display: flex;
  min-width: 180px;
`;
export const LogoContainer = styled.div`
  margin-left: 8px;
  margin-top: 8px;
  cursor: pointer;
`;

export const LogoTitle = styled.div`
  font-family: "PingFang TC";
  margin-left: 10px;
  margin-top: -8px;
  font-weight: 600;
  font-size: 22px;
  background: linear-gradient(126.9deg, #f8f3d1 8.11%, #fce29c 61.63%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export const LogoBody = styled.div`
  font-family: "Heiti TC";
  font-weight: 400;
  font-size: 12px;
  margin-top: 4px;
  color: #ffffff;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  margin-right: 2vw;
`;

export const MenuItem = styled.div`
  padding: 14px 30px;
  font-weight: 400;
  font-size: 18px;
  text-align: left;
  color: #f8db91;
`;

export const LogoImg = styled.img.attrs({ src: Logo })`
  margin-top: -14px;
  width: 40px;
`;
