import { useState, useEffect, useRef } from "react";
import {
  DiamondCardWrapper,
  DiamondCardTextContainer,
  DiamondWrapper,
} from "@styles/mobile/DiamondCard";

type PropsType = {
  body: string;
  diamond: any;
};

const DiamondCard = ({ body, diamond }: PropsType) => {
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef(null);

  // Function to check if the DiamondCard is in the viewport
  const handleScroll = () => {
    if (cardRef.current) {
      const rect = cardRef.current.getBoundingClientRect();
      if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        setIsVisible(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <DiamondCardWrapper ref={cardRef} isVisible={isVisible}>
      <DiamondWrapper>
        <img src={diamond} width={200} />
      </DiamondWrapper>
      <DiamondCardTextContainer>{body}</DiamondCardTextContainer>
    </DiamondCardWrapper>
  );
};

export default DiamondCard;
