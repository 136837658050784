import styled, { keyframes, css } from "styled-components";

// Keyframes for the animation
const scrollUpAnimation = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const DiamondCardWrapper = styled.div<{ isVisible: boolean }>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) =>
    isVisible ? "translateY(0)" : "translateY(100px)"};
  ${({ isVisible }) =>
    isVisible &&
    css`
      animation: ${scrollUpAnimation} 1s ease-out;
    `}
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  color: white;
  position: relative;
`;

export const DiamondWrapper = styled.div`
  z-index: 2;
`;

export const DiamondCardTextContainer = styled.div`
  text-align: center;
  background: linear-gradient(
    56deg,
    rgba(1, 188, 200, 0.05) 0%,
    rgba(1, 188, 200, 0.25) 100%
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
  margin-top: -24px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 35px 25px 25px;
  display: flex;
  font-size: 12px;
  font-weight: 200px;
`;

export const DiamondCardTitle = styled.div`
  position: absolute;
  top: 96px;
  left: 132px;
  opacity: 0.7;
  font-weight: 400;
  font-size: 12px;
  z-index: 3;
`;
