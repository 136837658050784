import React, { useState, useRef, useEffect } from "react";
import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { ABOUT_PAGE, CONTACT_PAGE, HOME_PAGE, TECH_PAGE } from "../../Routes";

/* Components */
import Button, { BUTTON_TYPE } from "@common/Button";
import Dropdown from "@desktop/DropdownSection";

/* Styles */
import {
  Wrapper,
  MainContent,
  LogoContainer,
  ButtonGroup,
  MenuItem,
  LogoImg,
  MenuLists,
} from "@styles/desktop/Navbar";

const Navbar = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const navigate = useNavigate();

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    message.info("Click on menu item.");
    navigate(ABOUT_PAGE);
  };

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  }, []);

  return (
    <>
      <Wrapper>
        <MainContent>
          <LogoContainer onClick={() => navigate(HOME_PAGE)}>
            <LogoImg />
          </LogoContainer>
          <ButtonGroup>
            <MenuLists>
              <MenuItem onClick={() => navigate(ABOUT_PAGE)}>About</MenuItem>
              <MenuItem onClick={() => navigate(TECH_PAGE)}>
                Technology
              </MenuItem>
              <MenuItem onClick={() => navigate(CONTACT_PAGE)}>
                Contact
              </MenuItem>
            </MenuLists>
            <Button
              variant={BUTTON_TYPE.GHOST}
              overrideStyle={{
                color: "white",
                borderRadius: "60px",
                border: "1px solid rgba(1, 188, 200, 0.50",
                padding: "8px 16px",
                marginTop: "4px",
                fontWeight: 300,
                fontSize: "14px",
                marginBottom: "4px",
              }}
              onClick={() => navigate(CONTACT_PAGE)}
            >
              Test our tech
            </Button>
          </ButtonGroup>
        </MainContent>
        {isDropdownVisible && <Dropdown />}
      </Wrapper>
    </>
  );
};

export default Navbar;
