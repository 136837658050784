import styled from "styled-components";
import doctor from "@assets/images/doctor.png";

export const Wrapper = styled.div`
  width: 100%;
  background-color: #111224;
  padding-bottom: 2vh;
`;

export const AboutHeaderSection = styled.section`
  border-radius: 0px 0px 24px 24px;
  background: linear-gradient(
    95deg,
    rgba(1, 188, 200, 0.5) 0%,
    rgba(144, 255, 228, 0.5) 100%
  );
  justify-content: flex-start;
  align-items: flex-end;
  min-height: 180px;
  display: flex;
`;

export const AboutHeaderBreadcrumb = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  color: white;
  letter-spacing: 1.2px;
`;

export const BreadcrumbLine = styled.div`
  background-color: white;
  width: 20px;
  height: 1px;
`;

export const BreadcrumbText = styled.div`
  margin-left: 10px;
  font-size: 14px;
  line-height: 1.6em;
`;

export const IntroSectionHeader = styled.div`
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.4em;
  text-align: left;
`;

export const IntroSectionSubHeader = styled.div`
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.4em;
  margin-top: 10px;
  text-align: left;
`;

export const IntroSectionBody = styled.p`
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  font-weight: 300;
  opacity: 0.7;
  line-height: 1.7em;
`;

export const PeopleSection = styled.div`
  cursor: pointer;
  border-radius: 24px;
  padding-top: 30px;
  padding-bottom: 80px;
  margin-top: 40px;
  background-image: linear-gradient(275deg, #e3f8ff 0%, #9fc7d3 100%);
`;

export const PeopleSectionHeader = styled.div`
  text-align: left;
  color: #111224;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1.4em;
`;

export const SkillSectionHeader = styled.div`
  color: #fff;
  font-size: 25px;
  font-weight: 300;
  line-height: 1.4em;
  text-align: left;
`;

export const SkillSectionSubheader = styled.div`
  color: #fff;
  font-size: 15px;
  line-height: 1.4em;
  text-align: left;
  word-spacing: 1px;
  font-weight: 200;
  margin-top: 20px;
  letter-spacing: 0.8px;
`;

export const Diamond = styled.div`
  width: 100px;
  height: 100px;
  background: linear-gradient(
    95deg,
    rgba(1, 188, 200, 0.5) 0%,
    rgba(144, 255, 228, 0.5) 100%
  );
  transform: rotate(45deg) scaleX(1.5);
  position: relative;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5); /* Add shadow */
  border: 0.1px solid #0e567e;
`;
