type PropsType = {
  overrideStyle?: any;
};

const Linkedin = ({ overrideStyle }: PropsType) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M8.8261 18H6V9.1364H8.8261V18ZM7.4136 7.9545C6.6319 7.9545 6 7.2922 6 6.4767C6 5.6613 6.633 5 7.4136 5C8.1925 5 8.8261 5.6625 8.8261 6.4767C8.8261 7.2922 8.1925 7.9545 7.4136 7.9545ZM19 18H16.283V13.6864C16.283 12.6576 16.2643 11.3346 14.8705 11.3346C13.4558 11.3346 13.2382 12.4549 13.2382 13.6119V18H10.5217V9.1299H13.1297V10.3418H13.1664C13.5293 9.6445 14.4161 8.9095 15.7387 8.9095C18.4913 8.9095 19 10.7466 19 13.135V18Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
export default Linkedin;
