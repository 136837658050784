import { LongContentCardFooterWrapper } from "@styles/desktop/Card";

import ArrowCircle from "@assets/icons/ArrowCircle";

type PropsType = {
  text: string;
  overrideTextStyle?: any;
};

const ArrowButtonText = ({ text, overrideTextStyle }: PropsType) => {
  return (
    <LongContentCardFooterWrapper style={{ ...overrideTextStyle }}>
      <ArrowCircle overrideStyle={{ marginRight: "12px" }} />
      {text}
    </LongContentCardFooterWrapper>
  );
};

export default ArrowButtonText;
