import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ABOUT_PAGE } from "../../Routes";
import {
  Wrapper,
  Header,
  HeaderSuffix,
  BodyItemText,
  BodyFooterText,
} from "@styles/desktop/Card";

import Counter from "@common/AnimatedCounter";

type PropsType = {
  title: string | number;
  titleSuffix: string;
  text: string;
  footer: string;
  alignDirection?: string;
  isMobile?: boolean;
};

const ContentCard = ({
  title,
  titleSuffix,
  text,
  footer,
  alignDirection = "left",
  isMobile = false,
}: PropsType) => {
  const marginTop = isMobile ? "30px" : "0px";
  const padding = isMobile ? "20px" : "40px 20px";
  const [isVisible, setIsVisible] = useState(false);
  const divRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    });

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, []);

  return (
    <Wrapper
      ref={divRef}
      isVisible={isVisible}
      style={{ justifyContent: alignDirection, marginTop }}
    >
      <div style={{ padding }}>
        <div style={{ display: " flex" }}>
          <Header isMobile={isMobile}>{title}</Header>
          <HeaderSuffix isMobile={isMobile}>{titleSuffix}</HeaderSuffix>
        </div>
        <BodyItemText isMobile={isMobile}>{text}</BodyItemText>
        <BodyFooterText isMobile={isMobile}>{footer}</BodyFooterText>
      </div>
    </Wrapper>
  );
};

export default ContentCard;
