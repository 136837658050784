import Navbar from "@mobile/Navbar";

/* Styles */
import {
  Wrapper,
  ContentWrapper,
  StatementWrapper,
} from "@styles/mobile/HomePage";

import {
  AboutHeaderSection,
  AboutHeaderBreadcrumb,
  BreadcrumbLine,
  BreadcrumbText,
  IntroSectionHeader,
  IntroSectionSubHeader,
  IntroSectionBody,
  PeopleSection,
  PeopleSectionHeader,
  SkillSectionHeader,
  SkillSectionSubheader,
} from "@styles/mobile/AboutPage";

/* Components */
import BottomContent from "@desktop/BottomSection";
import PersonCard from "@common/PersonCard";
import ArrowButtonText from "@desktop/ArrowTextButton";
import DiamondCard from "./DiamondCard";
import AIEngDiamond from "@assets/images/diamond/ai_eng.svg";
import MedicalExpertDiamond from "@assets/images/diamond/medical_experts.svg";
import FullStackDiamond from "@assets/images/diamond/full_stack.svg";
import CogScienceDiamond from "@assets/images/diamond/cognitive.svg";
import PMDiamond from "@assets/images/diamond/pm.svg";
import UXDiamond from "@assets/images/diamond/ui_ux.svg";
import DSDiamond from "@assets/images/diamond/ds.svg";
import Footer from "@mobile/Footer";

const AboutPage = () => {
  return (
    <>
      <Navbar />
      <Wrapper>
        <AboutHeaderSection>
          <ContentWrapper>
            <AboutHeaderBreadcrumb>
              <BreadcrumbLine />
              <BreadcrumbText>About</BreadcrumbText>
            </AboutHeaderBreadcrumb>
          </ContentWrapper>
        </AboutHeaderSection>
        <ContentWrapper>
          <div style={{ marginBottom: "6vh", textAlign: "left" }}>
            <StatementWrapper>
              Our Vision is to
              <span style={{ color: "#01bcc8" }}>
                {"  "}revolutionize medical diagnostics by eliminating cognitive
                bias,
              </span>
            </StatementWrapper>
          </div>
          <PersonCard
            name="David Whitney"
            title="Director of Vision Science UC Berkeley"
            email="david@mednerva.ai"
            phoneNumber="+12 3456 8799"
            isMobile
          />
          <div style={{ textAlign: "left", marginTop: "6vh" }}>
            <IntroSectionHeader>Inventing MEDNERVA</IntroSectionHeader>
            <IntroSectionSubHeader>
              Understanding the how bias is first identified in medical system
              with cutting-edge research
            </IntroSectionSubHeader>
            <IntroSectionBody>
              “To solve critical problems in healthcare, we must address their
              root cause: diagnostic bias. Our mission is to provide tools that
              reduce human error and save lives,” says Whitney, Founder & CEO.
              This principle from the MedNerva leadership team drives our vision
              of transforming healthcare through innovation.
            </IntroSectionBody>
            <IntroSectionBody>
              <strong>How MEDNERVA came about</strong>
              <br />
              The story began at UC Berkeley's Perception Lab, where
              cutting-edge research on human cognition and perception revealed
              the impact of bias in diagnostic imaging. The team, led by some of
              the world’s foremost experts in neuroscience and healthcare,
              realized the magnitude of this issue. With decades of clinical and
              research expertise, MedNerva’s technology leverages AI to reduce
              cognitive and perceptual biases, providing clinicians with
              reliable diagnostic support. This groundbreaking solution emerged
              from an unwavering commitment to improving patient care and
              healthcare outcomes globally
            </IntroSectionBody>
            <ArrowButtonText
              text="More about technology"
              overrideTextStyle={{ fontSize: "15px", opacity: "0.8" }}
            />
          </div>
        </ContentWrapper>
        <PeopleSection>
          <ContentWrapper>
            <PeopleSectionHeader>Meet the leadership team</PeopleSectionHeader>
            <div style={{ height: "40px" }} />
            <PersonCard
              name="David Whitney"
              title="Director of Vision Science UC Berkeley"
              email="david@mednerva.ai"
              phoneNumber="+12 3456 8799"
              isMobile
            />
            <div style={{ height: "30px" }} />
            <PersonCard
              name="David Whitney"
              title="Director of Vision Science UC Berkeley"
              email="david@mednerva.ai"
              phoneNumber="+12 3456 8799"
              isMobile
            />
            <div style={{ height: "30px" }} />
            <PersonCard
              name="David Whitney"
              title="Director of Vision Science UC Berkeley"
              email="david@mednerva.ai"
              phoneNumber="+12 3456 8799"
              isMobile
            />
          </ContentWrapper>
        </PeopleSection>
        <ContentWrapper>
          <SkillSectionHeader>Our skills</SkillSectionHeader>
          <SkillSectionSubheader>
            At MEDNERVA, we've built a powerhouse team of specialists to tackle
            diagnostic bias in healthcare head-on. Our team is uniquely
            positioned to bring world-class solutions to the medical diagnostics
            industry.
          </SkillSectionSubheader>
          {/* AI ENGINEERS */}
          <DiamondCard
            body="Building advanced machine learning models that enhance diagnostic accuracy by mitigating clinician bias."
            diamond={AIEngDiamond}
          />
          <DiamondCard
            body="Pioneering research into how human perception impacts medical
                  diagnostics, helping shape MedNerva's technology to reduce
                  cognitive biases in decision-making"
            diamond={CogScienceDiamond}
          />
          <DiamondCard
            body="Collaborating closely with clinicians to ensure our AI models
                  are clinically relevant and effective"
            diamond={MedicalExpertDiamond}
          />
          <DiamondCard
            body="Our full stack engineer are skilled at architecting and
                  maintaining MedNerva's tech stack for seamless integration
                  with healthcare systems"
            diamond={FullStackDiamond}
          />
          <DiamondCard
            body="Guiding product strategy and development, ensuring MedNerva’s
                  solutions meet the needs of healthcare professionals"
            diamond={PMDiamond}
          />
          <DiamondCard
            body="Ensuring the platform is intuitive and accessible,
                  streamlining workflows for clinicians and improving adoption
                  rates"
            diamond={UXDiamond}
          />
          <DiamondCard
            body="Our scientists are skilled at designing and refining
                  algorithms that process and analyze vast amounts of medical
                  imaging data"
            diamond={DSDiamond}
          />
        </ContentWrapper>
        <Footer />
      </Wrapper>
    </>
  );
};

export default AboutPage;
