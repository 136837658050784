import styled from "styled-components";
import Logo from "@assets/images/logo.png";

export const Wrapper = styled.header`
  z-index: 20;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: fixed;
  inset: 25px auto auto;
`;

export const MainContent = styled.nav`
  background-color: #040716cc;
  border-radius: 100vw;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px 4px 12px;
  display: flex;
  z-index: 20;
`;
export const LogoContainer = styled.div`
  cursor: pointer;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const MenuItem = styled.div`
  color: #fff;
  text-align: center;
  margin-left: 12px;
  margin-right: 12px;
  font-size: 15px;
  text-align: center;
  letter-spacing: 2px;
  font-weight: 300;
  cursor: pointer;
`;

export const LogoImg = styled.img.attrs({ src: Logo })`
  width: 50px;
`;

export const MenuLists = styled.ul`
  color: #fff;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
  list-style-type: none;
  display: flex;
  margin-top: 2px;
`;
