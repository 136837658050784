import styled from "styled-components";

export const GetInTouchSection = styled.section<{ hideBackground: boolean }>`
  border-radius: 24px;
  background: ${(props) =>
    props.hideBackground
      ? "none"
      : "linear-gradient(69deg, rgba(123, 104, 255, 0.05) 0%, rgba(123, 104, 255, 0.25) 100%)"};
  justify-content: flex-start;
  align-items: flex-end;
  min-height: 250px;
  display: flex;
  color: white;
  padding: 60px 0;
`;

export const GetInTouchFlexWrapper = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

export const GetInTouchTextContentWrapper = styled.div`
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  margin-right: 80px;
`;

export const GetInTouchHeader = styled.h1`
  font-size: 32px;
  line-height: 1.4em;
  font-weight: 500;
`;

export const GetInTouchSubHeader = styled.p`
  font-size: 16px;
  line-height: 1.5em;
  margin-bottom: 30px;
`;

export const GetInTouchImageWrapper = styled.div`
  width: 50%;
`;

export const GetInTouchImage = styled.img``;
