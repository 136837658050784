import { Row, Col, Divider as AntDivider } from "antd";
import {
  InfoHeader,
  InfoBody,
  ContentWrapper,
  BottomSectionContainer,
  BottomSectionHeader,
} from "@styles/desktop/HomePage";

import { LogoImg } from "@styles/desktop/Navbar";

const BottomSection = () => {
  return (
    <ContentWrapper>
      <BottomSectionHeader>
        <LogoImg style={{ width: "120px" }} />
      </BottomSectionHeader>
      <Row style={{ marginTop: "10px" }}>
        <Col span={16}>
          <InfoHeader>MEDNERVA - Away From Bias</InfoHeader>
          <InfoBody>MEDNERVA.AI</InfoBody>
          <InfoBody>
            University Avenue and, Oxford St, Berkeley, CA 94720
          </InfoBody>
        </Col>
        <Col span={4}>
          <InfoBody>ABOUT</InfoBody>
          <InfoBody>TECHNOLOGY</InfoBody>
          <InfoBody>CONTACT</InfoBody>
          <InfoBody>TEST OUR TECH</InfoBody>
        </Col>
        {/* <Col span={4}>
          <InfoHeader style={{ textAlign: "left" }}>联系我们</InfoHeader>
          <InfoBody style={{ marginBottom: "30px" }}>
            marketing@czone.pro
          </InfoBody>
        </Col> */}
      </Row>
      <AntDivider style={{ borderTop: "1px solid #ffffff26" }} />
      <Row style={{ marginTop: "2px" }}>
        <Col span={8}>
          <InfoBody>Copywrite @2024 MEDNERVA All Rights Reserved</InfoBody>
        </Col>
        <Col span={8}>
          {/* <InfoBody>
            <a href="http://beian.miit.gov.cn" style={{ color: "white" }}>
              沪ICP备2023014199号
            </a>
          </InfoBody> */}
        </Col>
        {/* <Col span={8}>
          <InfoBody>隐私条款</InfoBody>
        </Col> */}
      </Row>
    </ContentWrapper>
  );
};

export default BottomSection;
