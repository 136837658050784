import Navbar from "@desktop/Navbar";
import { Form, Input as rawInput, Modal } from "antd";
import styled from "styled-components";

/* Styles */
import {
  Wrapper,
  ContentWrapper,
  WhiteText,
  IntroSectionContainer,
  CardContainer,
} from "@styles/desktop/HomePage";
import {
  AboutHeaderSection,
  AboutHeaderBreadcrumb,
  BreadcrumbLine,
  BreadcrumbText,
  DetailSection,
  DetailSectionHeader,
  DetailSectionSubHeader,
  EssentialSection,
  GridLayout,
  EssentialHeader,
  EssentialSubheader,
  EssentialLearnMoreWrapper,
  EssentialLearnMoreHeader,
  EssentialLearnMoreBody,
} from "@styles/desktop/TechPage";
import { Header, Subheader } from "@styles/desktop/AboutPage";

/* Components */
import BottomContent from "@desktop/BottomSection";
import IconCard from "@common/IconCard";
import Button from "@common/Button";
import ContactSection from "@common/ContactSection";

/* Assets */
import Doctor from "@assets/images/doctor.png";

const Input = styled(rawInput)`
  &::placeholder {
    color: white; /* Your custom placeholder color */
    opacity: 1; /* Override opacity */
  }
`;

const TechPage = () => {
  const [form] = Form.useForm();
  const onSubmit = async (values: any) => {
    Modal.success({
      content: "Thanks for the message, we will get in touch with you soon!",
    });
    // await SaveCompanyInfoService(values);
  };
  return (
    <>
      <Navbar />
      <Wrapper>
        <AboutHeaderSection
          style={{
            background:
              "linear-gradient(95deg, rgba(1, 188, 200, 0.50) 0%, rgba(123, 104, 255, 0.50) 100%), #111224",
          }}
        >
          <ContentWrapper>
            <AboutHeaderBreadcrumb>
              <BreadcrumbLine />
              <BreadcrumbText>Technology</BreadcrumbText>
            </AboutHeaderBreadcrumb>
          </ContentWrapper>
        </AboutHeaderSection>
        <ContentWrapper>
          <div style={{ marginBottom: "2vh" }} />
          <WhiteText>
            Committed to solving critical healthcare challenges through{" "}
            <span style={{ color: "#01bcc8" }}>innovative AI technology</span>
          </WhiteText>
          <div style={{ marginBottom: "8vh" }} />
          <IntroSectionContainer>
            <video
              width="100%"
              src="https://cdn.prod.website-files.com/666947d3f0c542911e8e494b%2F666c097108b6a32121284ed6_sasha-explainer-bg-transcode.mp4"
              autoPlay={true}
            ></video>
          </IntroSectionContainer>
        </ContentWrapper>
        <DetailSection>
          <ContentWrapper>
            <DetailSectionHeader>
              Empowering Medical Diagnostics
            </DetailSectionHeader>
            <DetailSectionSubHeader>
              MEDNERVA is a scalable AI-driven solution that reduces diagnostic
              bias in medical imaging. Our technology analyzes clinician
              interpretations, image sequences, and diagnostic patterns to
              ensure unbiased, accurate results for each patient. By integrating
              seamlessly into existing medical imaging systems, MEDNERVA
              enhances diagnostic precision, providing healthcare providers with
              reliable insights.
            </DetailSectionSubHeader>

            <GridLayout>
              <Header>Our Skills</Header>
              <Subheader>
                Embedding MEDNERVA's AI into the diagnostic workflow to analyze
                image sequences and detect bias during clinician interpretation
              </Subheader>
            </GridLayout>
          </ContentWrapper>
          <CardContainer>
            <IconCard
              prefix="01"
              title="Embed"
              body="Embedding MEDNERVA’s AI into the diagnostic workflow to analyze image sequences and detect bias during clinician interpretation."
            />
            <IconCard
              prefix="02"
              title="Analyze"
              body="The advanced MEDNERVA engine evaluates each image sequence and clinician decision, ensuring every diagnosis is unbiased and consistent."
            />
            <IconCard
              prefix="03"
              title="Alert"
              body="MEDNERVA alerts clinicians when diagnostic biases are detected, providing real-time recommendations for an unbiased, accurate outcome."
            />
          </CardContainer>
        </DetailSection>
        <EssentialSection>
          <ContentWrapper>
            <GridLayout>
              <EssentialHeader>MEDNERVA essentials</EssentialHeader>
              <EssentialSubheader>
                Our AI-powered technology is designed for scalability,
                robustness, and improving medical diagnostics by mitigating
                clinician bias. We build with these core principles to enhance
                diagnostic accuracy.
              </EssentialSubheader>
            </GridLayout>
          </ContentWrapper>
          <CardContainer>
            <IconCard
              title="Robustness"
              body="MEDNERVA ensures resilient performance across various medical imaging platforms, maintaining diagnostic integrity. Our system is built to withstand evolving diagnostic environments with consistent accuracy."
            />
            <IconCard
              title="AI-Powered"
              body="As diagnostic challenges evolve, so does MEDNERVA. Our AI uses iterative learning processes to continually improve diagnostic outcomes, applying insights from real-world data to ensure future reliability."
            />
            <IconCard
              title="Scalable"
              body="MEDNERVA integrates seamlessly with healthcare systems globally. Whether in large hospitals or remote diagnostics, our solution scales to meet the needs of diverse medical environments.
"
            />
            <IconCard
              title="Embed"
              body="MEDNERVA’s AI significantly reduces diagnostic bias, improving patient outcomes. It delivers consistent and accurate results without the risk of subjective interpretation errors, providing high confidence in medical decisions."
            />
          </CardContainer>
          <ContentWrapper>
            <EssentialLearnMoreWrapper>
              <EssentialLearnMoreHeader>
                Integrating Our technology
              </EssentialLearnMoreHeader>
              <EssentialLearnMoreBody>
                MEDNERVA technology seamlessly integrates into existing
                diagnostic systems, helping clinicians and healthcare providers
                reduce bias and improve diagnostic outcomes from day one.
              </EssentialLearnMoreBody>
              <Button>I want to hear more</Button>
            </EssentialLearnMoreWrapper>
          </ContentWrapper>
        </EssentialSection>
        <ContactSection hideBackground={false} />
        <BottomContent />
      </Wrapper>
    </>
  );
};

export default TechPage;
