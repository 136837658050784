import { connect } from "react-redux";

import { getShouldRenderMobileComponent } from "@data/selectors/BrowserSelectors";

import DesktopAboutPage from "@desktop/AboutPage";
import MobileAboutPage from "@mobile/AboutPage";

const mapStateToProps = (state: any) => ({
  shouldRenderMobileComponent: getShouldRenderMobileComponent(state),
});

type PropsType = ReturnType<typeof mapStateToProps>;

const AboutPageSwitch = ({ shouldRenderMobileComponent }: PropsType) => {
  return shouldRenderMobileComponent ? (
    <MobileAboutPage />
  ) : (
    <DesktopAboutPage />
  );
};

export default connect(mapStateToProps)(AboutPageSwitch);
