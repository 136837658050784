import styled from "styled-components";

export const ButtonWrapper = styled.div<{
  disable?: boolean;
  cssString?: string;
}>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  font-weight: 400;
  border-radius: 60px;
  background: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 74px;
  color: black;
  transition: all 0.15s ease-in-out;
  letter-spacing: 0.5px;
  opacity: 0.8;
  ${({ cssString }) => cssString}
`;
