/* Styles */
import {
  Wrapper,
  StatementWrapper,
  Divider,
  Background,
  HelloSection,
  ContentWrapper,
  Title,
  Subtitle,
  DetailSection,
  DetailSectionHeader,
  DetailSectionSubHeader,
  StorySection,
  StorySectionHeader,
  StorySectionSubHeader,
  StorySectionBody,
} from "@styles/mobile/HomePage";

/* Components */
import Navbar from "@mobile/Navbar";
import ContentCard from "@desktop/ContentCard";
import ImageCard from "@common/ImageCard";
import LongContentCard from "@desktop/LongContentCard";
import PersonCard from "@common/PersonCard";
import Button, { BUTTON_TYPE } from "@common/Button";
import Doctor from "@assets/images/doctor.png";
import ArrowButtonText from "@desktop/ArrowTextButton";
import Footer from "./Footer";

const HomePage = () => {
  const firstImageBody = [
    "Collect sequences of patient images",
    "Encrypt information during collection",
    "Maintaining image quality",
  ];
  const SecondImageBody = [
    "Analyze images with AI service",
    "Apply image sequence reshuffling algorithm",
    "Random validation ensures accuracy",
  ];
  const ThirdImageBody = [
    "Provide unbiased diagnostic insights",
    "Ensure accountability",
    "Fine-tune and improve the AI model",
  ];

  return (
    <>
      <Navbar />
      <Wrapper>
        <HelloSection>
          <ContentWrapper>
            <Title>
              Revolutionizing Medical Diagnostics with AI-Powered Bias Reduction
            </Title>
            <Subtitle>
              Enhance diagnostic accuracy and reduce healthcare costs
            </Subtitle>
            <Button
              overrideStyle={{
                maxWidth: "120px",
                margin: "8px auto",
                fontSize: "12px",
                padding: "4px 10px",
                Background: "white",
              }}
              onClick={() => {}}
            >
              Explore our tech
            </Button>
          </ContentWrapper>
        </HelloSection>
        <Background />
        {/* <motion.div
          animate={Number(scrollYProgress) > 0.1 ? "visible" : "hidden"}
          style={{ scaleX }}
        >
          <Divider />
        </motion.div> */}
        <ContentWrapper>
          <StatementWrapper>
            Medical diagnostics are critical to patient care.
            <span style={{ color: "#01bcc8" }}>
              But ensuring accuracy can be a challenge.
            </span>
          </StatementWrapper>
          <ContentCard
            title={90}
            titleSuffix={"B"}
            text={
              "$90 billion is lost annually due to diagnostic errors in the U.S. healthcare system."
            }
            footer={"Johns Hopkins University Study (2023)"}
            isMobile
          />
          <ContentCard
            title={75}
            titleSuffix={"%"}
            text={
              "75% of errors are caused by human biases, leading to misdiagnosis and delayed treatment."
            }
            footer={"National Academy of Medicine (2023)"}
            isMobile
          />
          <ContentCard
            title={21}
            titleSuffix={"B"}
            text={
              "$21 billion annual loss in radiology and dermatology by 2030 due to these biases."
            }
            footer={"Radiological Society of North America (2023)"}
            isMobile
          />
          <Divider />
          <StatementWrapper>
            Medical diagnostics are critical to patient care.
            <span style={{ color: "#01bcc8" }}>
              But ensuring accuracy can be a challenge.
            </span>
          </StatementWrapper>
        </ContentWrapper>
        <DetailSection>
          <ContentWrapper>
            <DetailSectionHeader>MEDNERVA</DetailSectionHeader>
            <DetailSectionSubHeader>
              Delivering reliable and scalable bias reduction in medical
              diagnostics
            </DetailSectionSubHeader>
            <ImageCard
              prefix={"01"}
              title={"Image Collection"}
              body={firstImageBody}
              image={Doctor}
              isMobile
            />
            <ImageCard
              prefix={"01"}
              title={"AI Analysis"}
              body={SecondImageBody}
              image={Doctor}
              isMobile
            />
            <ImageCard
              prefix={"01"}
              title={"Unbiased Insights"}
              body={ThirdImageBody}
              image={Doctor}
              isMobile
            />
            <Button
              overrideStyle={{
                maxWidth: "120px",
                margin: "8px auto",
                fontSize: "12px",
                padding: "4px 10px",
                background: "black",
                color: "white",
                marginTop: "30px",
              }}
              onClick={() => {}}
            >
              Explore our tech
            </Button>
          </ContentWrapper>
        </DetailSection>
        <ContentWrapper>
          <div style={{ margin: "6vh auto", maxWidth: "80%" }}>
            <StatementWrapper>
              How will MERDNERVA be available?
            </StatementWrapper>
          </div>
          <LongContentCard
            title="API to existing diagnosis solution"
            text="
              Integrating MedNerva’s API into existing diagnostic solutions provides an easy way for hospitals and imaging centers to reduce bias. It's even more accessible for remote diagnosis providers"
            boldText="Easy to integrate, reduce bias in minutes"
            footer="Want to know more"
            isMobile
          />
          <LongContentCard
            title="Remote Diagnosis Platform"
            text="Introducing the next generation of remote diagnosis platforms, empowering clinicians with accurate, unbiased insights no matter where they are in the world. Find the best doctor for each patient based on their diagnosis history"
            boldText="Precision diagnostics, anywhere in the world"
            footer="Join the test waitlist"
            isMobile
          />
        </ContentWrapper>
        <StorySection>
          <ContentWrapper>
            <PersonCard
              name="David Whitney"
              title="Director of Vision Science UC Berkeley"
              email="david@mednerva.ai"
              phoneNumber="+12 3456 8799"
              isMobile
            />
            <StorySectionHeader>How it all started</StorySectionHeader>
            <StorySectionSubHeader>
              Frontier Research at UC Berkeley's Perception Lab
            </StorySectionSubHeader>
            <StorySectionBody>
              Mednerva’s journey began with Frontier Research at UC Berkeley’s
              Perception Lab Tackling neuroscience x healthcare
            </StorySectionBody>
            <ArrowButtonText
              text="Discover our story"
              overrideTextStyle={{ fontSize: "14px", fontWeight: "300" }}
            />
          </ContentWrapper>
        </StorySection>

        <Footer />
      </Wrapper>
    </>
  );
};

export default HomePage;
