import { Form, Input as rawInput, Modal } from "antd";
import styled from "styled-components";

/* Styles */
import { ContentWrapper } from "@styles/desktop/HomePage";
import {
  GetInTouchSection,
  GetInTouchFlexWrapper,
  GetInTouchTextContentWrapper,
  GetInTouchHeader,
  GetInTouchSubHeader,
  GetInTouchImageWrapper,
  GetInTouchImage,
} from "@styles/desktop/ContactSection";

/* Components */
import Button, { BUTTON_TYPE } from "@common/Button";

const Input = styled(rawInput)`
  &::placeholder {
    color: white; /* Your custom placeholder color */
    opacity: 1; /* Override opacity */
  }
`;

const { TextArea } = Input;

export const StyledTextArea = styled(TextArea)`
  color: white;
  height: 100px;
  background: #ffffff0d;
  border: 1px solid #ffffff1a;

  ::placeholder {
    color: white; /* Optional: Customize placeholder color */
  }
`;

type PropsType = { hideBackground?: boolean };

const ContactSection = ({ hideBackground = false }: PropsType) => {
  const [form] = Form.useForm();
  const onSubmit = async (values: any) => {
    Modal.success({
      content: "Thanks for the message, we will get in touch with you soon!",
    });
    // await SaveCompanyInfoService(values);
  };
  return (
    <>
      <GetInTouchSection hideBackground>
        <ContentWrapper>
          <GetInTouchFlexWrapper>
            <GetInTouchTextContentWrapper>
              <GetInTouchHeader>Get in touch</GetInTouchHeader>
              <GetInTouchSubHeader>
                We're excited to connect with you. Feel free to drop us a
                message anytime.
              </GetInTouchSubHeader>
              <Form form={form} name="collect_info" onFinish={onSubmit}>
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: "cannot leave empty" }]}
                >
                  <Input
                    placeholder="Full Name*"
                    style={{
                      color: "white",
                      height: "60px",
                      background: "#ffffff0d",
                      border: "1px solid #ffffff1a",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: "Cannot leave empty" }]}
                >
                  <Input
                    placeholder="Email*"
                    style={{
                      height: "60px",
                      background: "#ffffff0d",
                      border: "1px solid #ffffff1a",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="message"
                  rules={[{ required: true, message: "Cannot leave empty" }]}
                >
                  <StyledTextArea placeholder="Message*" />
                </Form.Item>
                <Button variant={BUTTON_TYPE.GHOST}>Send Message</Button>
              </Form>
            </GetInTouchTextContentWrapper>
            <GetInTouchImageWrapper>
              <GetInTouchImage src="https://cdn.prod.website-files.com/666947d3f0c542911e8e494b/66756305d22ecc52599b178d_Sasha_034_full%201.png" />
            </GetInTouchImageWrapper>
          </GetInTouchFlexWrapper>
        </ContentWrapper>
      </GetInTouchSection>
    </>
  );
};

export default ContactSection;
