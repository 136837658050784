import Navbar from "@desktop/Navbar";
import Circle from "@assets/icons/Circles";

/* Styles */
import {
  Wrapper,
  ContentWrapper,
  WhiteText,
  IntroSectionBody,
  IntroSectionHeader,
  IntroSectionPersonCard,
  IntroSectionPersonCardDetail,
  IntroSectionPersonCardLinkedInWrapper,
  IntroSectionPersonImage,
  IntroSectionSubHeader,
  IntroSectionPersonPhotoWrapper,
  IntroTextContainer,
  IntroSectionContainer,
} from "@styles/desktop/HomePage";
import {
  AboutHeaderSection,
  AboutHeaderBreadcrumb,
  BreadcrumbLine,
  BreadcrumbText,
  PeopleSection,
  PeopleCardWrapper,
  PeopleSectionHeader,
  GridLayout,
  Header,
  Subheader,
  SkillWrapper,
  SkillImage,
  DotWrapper,
  DotPlacer,
  ExplanationText,
} from "@styles/desktop/AboutPage";
import Linkedin from "@assets/icons/LinkedIn";

/* Components */
import BottomContent from "@desktop/BottomSection";
import PersonCard from "@common/PersonCard";
import ArrowButtonText from "@desktop/ArrowTextButton";
import Dot from "@assets/icons/Dot";

const AboutPage = () => {
  return (
    <>
      <Navbar />
      <Wrapper>
        <AboutHeaderSection>
          <ContentWrapper>
            <AboutHeaderBreadcrumb>
              <BreadcrumbLine />
              <BreadcrumbText>About</BreadcrumbText>
            </AboutHeaderBreadcrumb>
          </ContentWrapper>
        </AboutHeaderSection>
        <ContentWrapper>
          <div style={{ marginBottom: "2vh" }} />
          <WhiteText>
            Our Vision is to
            <br />
            <span style={{ color: "#01bcc8" }}>
              revolutionize medical diagnostics by eliminating cognitive bias,
            </span>
          </WhiteText>
          <div style={{ marginBottom: "8vh" }} />
          <IntroSectionContainer>
            <IntroTextContainer>
              <IntroSectionHeader>Inventing MEDNERVA</IntroSectionHeader>
              <IntroSectionSubHeader>
                Understanding the how bias is first identified in medical system
                with cutting-edge research
              </IntroSectionSubHeader>
              <IntroSectionBody>
                “To solve critical problems in healthcare, we must address their
                root cause: diagnostic bias. Our mission is to provide tools
                that reduce human error and save lives,” says Whitney, Founder &
                CEO. This principle from the MedNerva leadership team drives our
                vision of transforming healthcare through innovation.
              </IntroSectionBody>
              <IntroSectionBody>
                <strong>How MEDNERVA came about</strong>
                <br />
                The story began at UC Berkeley's Perception Lab, where
                cutting-edge research on human cognition and perception revealed
                the impact of bias in diagnostic imaging. The team, led by some
                of the world’s foremost experts in neuroscience and healthcare,
                realized the magnitude of this issue. With decades of clinical
                and research expertise, MedNerva’s technology leverages AI to
                reduce cognitive and perceptual biases, providing clinicians
                with reliable diagnostic support. This groundbreaking solution
                emerged from an unwavering commitment to improving patient care
                and healthcare outcomes globally
              </IntroSectionBody>
              {/* <IntroSectionBody>
                <strong>Driving change</strong>
                <br />
                MEDNERVA’S journey began with cutting edge research lab from UC
                Berkeley that focusing on human reception
              </IntroSectionBody> */}
              <ArrowButtonText text="Discover our story" />
            </IntroTextContainer>
            <IntroSectionPersonPhotoWrapper>
              <IntroSectionPersonCard>
                <IntroSectionPersonImage />
                <IntroSectionPersonCardDetail>
                  <h4>Dave Whitney</h4>
                  <div>Director of Vision Science UC Berkeley</div>
                </IntroSectionPersonCardDetail>
                <IntroSectionPersonCardLinkedInWrapper>
                  <Linkedin />
                </IntroSectionPersonCardLinkedInWrapper>
              </IntroSectionPersonCard>
            </IntroSectionPersonPhotoWrapper>
          </IntroSectionContainer>
        </ContentWrapper>
        <PeopleSection>
          <ContentWrapper>
            <PeopleSectionHeader>Meet the leadership team</PeopleSectionHeader>
            <PeopleCardWrapper>
              <PersonCard
                name="David Whitney"
                title="Director of Vision Science UC Berkeley"
                email="david@mednerva.ai"
                phoneNumber="+12 3456 8799"
              />
              <PersonCard
                name="David Whitney"
                title="Director of Vision Science UC Berkeley"
                email="david@mednerva.ai"
                phoneNumber="+12 3456 8799"
              />
              <PersonCard
                name="David Whitney"
                title="Director of Vision Science UC Berkeley"
                email="david@mednerva.ai"
                phoneNumber="+12 3456 8799"
              />
            </PeopleCardWrapper>
          </ContentWrapper>
        </PeopleSection>
        <ContentWrapper>
          <GridLayout>
            <Header>Our Skills</Header>
            <Subheader>
              At MedNerva, we've built a powerhouse team of specialists to
              tackle diagnostic bias in healthcare head-on.Our team is uniquely
              positioned to bring world-class solutions to the medical
              diagnostics industry.
            </Subheader>
          </GridLayout>
          <SkillWrapper>
            <SkillImage />
            <DotWrapper>
              {/*UX & UI*/}
              <DotPlacer top="79.5%" left="64%">
                <Dot color="#01BCC8" />
                <ExplanationText top="79.5%" left="64%">
                  Ensuring the platform is intuitive and accessible,
                  streamlining workflows for clinicians and improving adoption
                  rates
                </ExplanationText>
              </DotPlacer>
              {/*AI Engineer*/}
              <DotPlacer top="27.5%" left="25%">
                <Dot color="#01BCC8" />
                <ExplanationText top="27.5%" left="25%">
                  Building advanced machine learning models that enhance
                  diagnostic accuracy by mitigating clinician bias
                </ExplanationText>
              </DotPlacer>
              {/*Medical Expert*/}
              <DotPlacer top="28%" left="60.5%">
                <Dot color="#01BCC8" />
                <ExplanationText top="28%" left="60.5%">
                  Collaborating closely with clinicians to ensure our AI models
                  are clinically relevant and effective
                </ExplanationText>
              </DotPlacer>
              {/*Cognitive Neuroscientist*/}
              <DotPlacer top="52%" left="12.5%">
                <Dot color="#01BCC8" />
                <ExplanationText top="52%" left="12.5%">
                  Pioneering research into how human perception impacts medical
                  diagnostics, helping shape MedNerva’s technology to reduce
                  cognitive biases in decision-making
                </ExplanationText>
              </DotPlacer>
              {/*Data Scientists*/}
              <DotPlacer top="72%" left="31%">
                <Dot color="#01BCC8" />
                <ExplanationText top="72%" left="31%">
                  Our scientists are skilled at designing and refining
                  algorithms that process and analyze vast amounts of medical
                  imaging data
                </ExplanationText>
              </DotPlacer>
              {/*Full Stack*/}
              <DotPlacer top="60%" left="51.5%">
                <Dot color="#01BCC8" />
                <ExplanationText top="60%" left="51.5%">
                  Our full stack engineer are skilled at architecting and
                  maintaining MedNerva’s tech stack for seamless integration
                  with healthcare systems
                </ExplanationText>
              </DotPlacer>
              {/*Product Manager*/}
              <DotPlacer top="53.5%" left="70%">
                <Dot color="#01BCC8" />
                <ExplanationText top="53.5%" left="70%">
                  Guiding product strategy and development, ensuring MedNerva’s
                  solutions meet the needs of healthcare professionals
                </ExplanationText>
              </DotPlacer>
            </DotWrapper>
          </SkillWrapper>
        </ContentWrapper>
        <BottomContent />
      </Wrapper>
    </>
  );
};

export default AboutPage;
