export const BREAK_POINTS = {
  extraSmall: 400,
  small: 600,
  medium: 768,
  large: 1060,
};

export const PRODUCT_OPTIONS = [
  {
    value: "company",
    label: "公司结构优化",
  },
  {
    value: "employee",
    label: "员工日常管理",
  },
  {
    value: "recruitment",
    label: "人员队伍扩张",
  },
];

export const PEOPLE_OPTIONS = [
  {
    value: 1,
    label: "小于10人",
  },
  {
    value: 2,
    label: "10 ~ 50 人",
  },
  {
    value: 3,
    label: "50 ~ 100 人",
  },
  {
    value: 4,
    label: "100 ~ 500 人",
  },
  {
    value: 5,
    label: "500 人以上",
  },
];
