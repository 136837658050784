import styled from "styled-components";
export const Wrapper = styled.div`
  background: linear-gradient(0deg, #111224 0%, rgba(182, 182, 183, 0) 100%);
`;

export const MainContent = styled.div`
  display: flex;
  padding-bottom: 100px;
`;

export const Divider = styled.div`
  border: 1px solid #f9d744;
  max-width: 200px;
  margin-top: 60px;
`;

export const InfoBody = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #fde29d;
  padding-bottom: 4px;
  margin-left: 8px;
`;

export const TitleContainer = styled.div`
  max-width: 40vw;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-top: 10vh;
  margin-left: 10vw;
`;

export const AboutHeaderSection = styled.section`
  border-radius: 0px 0px 24px 24px;
  background: linear-gradient(
    95deg,
    rgba(1, 188, 200, 0.5) 0%,
    rgba(144, 255, 228, 0.5) 100%
  );
  justify-content: flex-start;
  align-items: flex-end;
  min-height: 250px;
  display: flex;
`;

export const AboutHeaderBreadcrumb = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  color: white;
  letter-spacing: 1.2px;
`;

export const BreadcrumbLine = styled.div`
  background-color: white;
  width: 25px;
  height: 1px;
`;

export const BreadcrumbText = styled.div`
  margin-left: 10px;
  font-size: 18px;
  line-height: 1.6em;
`;

export const DetailSection = styled.div`
  cursor: pointer;
  border-radius: 24px;
  padding-top: 60px;
  padding-bottom: 80px;
  margin-top: 40px;
  background-image: linear-gradient(275deg, #e3f8ff 0%, #9fc7d3 100%);
`;

export const DetailSectionHeader = styled.div`
  text-align: left;
  color: #111224;
  font-family: "DM Sans", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4em;
  width: 80%;
  margin-bottom: 10px;
  letter-spacing: 1.2px;
`;

export const DetailSectionSubHeader = styled.div`
  text-align: left;
  color: #111224;
  font-family: "DM Sans", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.2em;
  width: 80%;
  margin-bottom: 60px;
`;

export const EssentialSection = styled.section`
  color: white;
  font-family: DM Sans, sans-serif;
  font-size: 15px;
  line-height: 1.6em;
  padding-top: 60px;
  padding-bottom: 60px;
  display: block;
`;

export const GridLayout = styled.div`
  display: grid;
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 40px;
  margin-bottom: 60px;
  grid-auto-columns: 1fr;
`;

export const EssentialHeader = styled.h2`
  font-size: 32px;
  line-height: 1.4em;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const EssentialSubheader = styled.div`
  font-size: 19px;
  line-height: 1.5em;
  font-weight: 500;
  margin-top: 30px;
  grid-row-start: span 1;
  grid-column-start: span 2;
  grid-row-end: span 1;
  grid-column-end: span 2;
`;

export const EssentialLearnMoreWrapper = styled.div`
  text-align: center;
  background-image: url(https://cdn.prod.website-files.com/666947d3f0c542911e8e494b/6669ea06108605cbb48e004d_sasha-bg-02.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 80px 60px;
  display: flex;
  letter-spacing: 1px;
`;

export const EssentialLearnMoreHeader = styled.h1`
  font-size: 32px;
  line-height: 1.4em;
  font-weight: 400;
`;

export const EssentialLearnMoreBody = styled.p`
  font-size: 16px;
  line-height: 1.5em;
  max-width: 700px;
  margin-bottom: 60px;
`;
