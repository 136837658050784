import styled from "styled-components";
import Background from "@assets/images/skill_background.svg";

export const Wrapper = styled.div`
  background: linear-gradient(0deg, #111224 0%, rgba(182, 182, 183, 0) 100%);
`;

export const MainContent = styled.div`
  display: flex;
  padding-bottom: 100px;
`;

export const Divider = styled.div`
  border: 1px solid #f9d744;
  max-width: 200px;
  margin-top: 60px;
`;

export const InfoBody = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #fde29d;
  padding-bottom: 4px;
  margin-left: 8px;
`;

export const TitleContainer = styled.div`
  max-width: 40vw;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-top: 10vh;
  margin-left: 10vw;
`;

export const AboutHeaderSection = styled.section`
  border-radius: 0px 0px 24px 24px;
  background: linear-gradient(
    95deg,
    rgba(1, 188, 200, 0.5) 0%,
    rgba(144, 255, 228, 0.5) 100%
  );
  justify-content: flex-start;
  align-items: flex-end;
  min-height: 250px;
  display: flex;
`;

export const AboutHeaderBreadcrumb = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  color: white;
  letter-spacing: 1.2px;
`;

export const BreadcrumbLine = styled.div`
  background-color: white;
  width: 25px;
  height: 1px;
`;

export const BreadcrumbText = styled.div`
  margin-left: 10px;
  font-size: 18px;
  line-height: 1.6em;
`;

export const PeopleSection = styled.div`
  cursor: pointer;
  border-radius: 24px;
  padding-top: 60px;
  padding-bottom: 80px;
  margin-top: 40px;
  background-image: linear-gradient(275deg, #e3f8ff 0%, #9fc7d3 100%);
`;

export const PeopleCardWrapper = styled.div`
  display: grid;
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 0;
  margin-bottom: 0;
`;

export const PeopleSectionHeader = styled.div`
  text-align: left;
  color: #111224;
  font-family: "DM Sans", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4em;
  width: 80%;
  margin-bottom: 60px;
  letter-spacing: 1.2px;
`;

export const GridLayout = styled.div`
  display: grid;
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 40px;
  margin-bottom: 60px;
  grid-auto-columns: 1fr;
  color: white;
`;

export const Header = styled.h2`
  font-size: 32px;
  line-height: 1.4em;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const Subheader = styled.div`
  font-size: 18px;
  line-height: 1.5em;
  font-weight: 300;
  margin-top: 30px;
  grid-row-start: span 1;
  grid-column-start: span 2;
  grid-row-end: span 1;
  grid-column-end: span 2;
  opacity: 0.7;
`;

export const SkillWrapper = styled.div`
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

export const SkillImage = styled.img.attrs({ src: Background })`
  position: relative;
  width: 100%;
`;

export const DotWrapper = styled.div`
  inset: 0%;
  position: absolute;
`;

export const ExplanationText = styled.div<{ top: string; left: string }>`
  opacity: 0;
  position: absolute;
  top: calc(${(props) => props.top} - 130px);
  left: calc(${(props) => props.left} - 50px);
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 20px;
  border-radius: 10px;
  white-space: nowrap; /* Prevent text wrapping */
  z-index: 10;
  width: 200px;
  word-wrap: break-word;
  white-space: normal;
  transition: opacity 0.5s ease;
  font-size: 12px;
  font-weight: 300;

  /* Rectangle Shape */
  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
  }
`;

export const DotPlacer = styled.div<{ top: string; left: string }>`
  cursor: pointer;
  border: 1px solid #ffffff40;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding: 0;
  display: flex;
  position: absolute;
  inset: 0% auto auto 0%;
  transform: translate(-50%, -50%);
  top: ${(props) => props.top};
  left: ${(props) => props.left};

  &:hover ${ExplanationText} {
    opacity: 1; /* Fully visible on hover */
    visibility: visible; /* Make visible on hover */
  }
`;
